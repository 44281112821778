import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO title="About" />
    <div className="container">
      <div className="row">
        <div className="col-md-12 order-md-first">
          <h1>Hi, I'm <span className="primary">Alissa</span>!</h1>
          <p>
            Though I built my first website in 1998, I didn't fully transition to software engineering until 2014.
            Over the years, I've worked in both enterprise and startup environments, in front-end and full-stack roles,
            in-office and remotely, on teams ranging from 4 to 126 engineers.
          </p>
          <p>
            Currently, I’m an Open Source Engineer at Slack, where I help build and maintain Slack's SDKs.
          </p>
          <p>
            Before Slack, I was a Lead Instructor at Rithm School where I helped design and deliver an advanced curriculum on
            full-stack engineering. Prior to that, I was working remotely as a full-stack engineer for Pattern89 (formerly Quantifi), 
            helping build a system from the ground up that paired machine learning with social media advertising. 
          </p>
        </div>
      </div>
    </div>
  </Layout>
);

export default About;